import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import facebook_camera_header from "../images/facebook_camera_header.png"
import facebook_camera_main from "../images/facebook_camera_main.png"
import facebook_camera_effects from "../images/facebook_camera_effects.png"
import facebook_camera_text from "../images/facebook_camera_text.png"
import facebook_ama_home from "../images/facebook_ama_home.png"
import facebook_ama_campaign from "../images/facebook_ama_campaign.png"
import facebook_ama_create1 from "../images/facebook_ama_create1.png"
import facebook_ama_create2 from "../images/facebook_ama_create2.png"

const FacebookPage = () => (
  <Layout>
    <SEO title="Work" />
      <div class="nav_bar">
        <Link to="">About</Link>
        <Link to="/facebook/" activeClassName="active">Work</Link>
      </div>
      <div class="work_nav_bar">
        <Link to="/quora/" class="work_links">Quora</Link>
        <Link to="/facebook/" class="work_links" activeClassName="active">Facebook</Link>
        <Link to="/wanelo/" class="work_links">Wanelo</Link>
      </div>
    	<h1>Facebook</h1>
		    <ul class="list">
          <li>2014 - 2017</li>
        </ul>
        <p>At Facebook I worked on a number of projects across the core Facebook app and advertiser tools. 
        In addition to design work, I was heavily involved in design recruiting, 
        conducting 100+ interviews of designers and traveling to schools such as Harvard, RISD, Art Center, CIID, 
        and York University to give talks, interview university candidates, and conduct portfolio reviews.</p>

        <h2>Camera</h2>
        <ul class="list">
          <li>Role: Lead Product Designer</li>
          <li>2016 - 2017</li>
        </ul>
        <p>As part of the Facebook Stories team, my team built the Camera and ways to browse creative effects and tools. 
        We explored how AR could be integrated into the Facebook app, how to let people express themselves visually 
        without having to take photos, and how to let an ecosystem of artists create and share effects for others to use.</p>
        <div class="mockups_container">
          <img src={facebook_camera_main} class="mockup" alt="" />
          <div class="framed_video">
            <iframe src="https://player.vimeo.com/video/353660106?autoplay=1&loop=1&autopause=0" width="240" height="330" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
          </div>
        </div>
        <p class="subtitle">The Facebook Camera and different modes</p>

        <div class="mockups_container">
          <img src={facebook_camera_effects} class="mockup" alt="" />
          <img src={facebook_camera_text} class="mockup" alt="" />
        </div>

        <div class="mockups_container">
          <div class="framed_video">
            <iframe src="https://player.vimeo.com/video/353660087?&loop=1&autopause=0" width="240" height="330" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
          </div>
          <div class="framed_video">
            <iframe src="https://player.vimeo.com/video/353660067?&loop=1&autopause=0" width="240" height="330" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
          </div>
        </div>
        <p class="subtitle">Some explorations I did for allowing people to easily take multiple photos and browse between different photos without losing their selection.</p>

        <h2>Composer</h2>
        <ul class="list">
          <li>Role: Lead Product Designer</li>
          <li>2015 - 2016</li>
        </ul>
        <p>I led the first major redesign of Facebook's composer in over five years. 
        The composer was where people came to share what was on their minds, photos of their friends, 
        and places they visited. For a long time the composer only supported those three uses, 
        but by 2015 we were working on many new types of content for people to share, including 
        live video, music, polls, collages and slideshows, and many more. We redesigned the composer 
        to support all of these new content types while staying easy to use, and built it so that any product team 
        at Facebook such as Groups, Pages, or Marketplace could adopt and customize the composer to their needs. 
        The composer is used by more than 500 million people sharing with their friends every month.</p>

        <h2>Ads Manager for iOS and Android</h2>
        <ul class="list">
          <li>Role: Lead Product Designer</li>
          <li>2014 - 2015</li>
        </ul>
        <p>Ads Manager for iOS and Android was released in 2015 to allow any advertiser on Facebook to manage, edit, 
        and create ads from their phone. Our main challenge in designing this app was distilling all of the robust functionality 
        of the Ads Manager web product into a mobile app that supported both the use cases of small businesses who primarily 
        relied on their phones to run their business and of advertisers at large companies or ad agencies who needed lightweight 
        editing tools on their phones when they were away from their computers. Today, many of the 6 million advertisers 
        on Facebook use the Ads Manager app to create, modify, or monitor their ads, and billions of dollars in 
        annual ad revenue are managed through the app.</p>
        <p>The Ads Manager app was also the first app built fully in React Native at Facebook, and our team worked closely with 
        the React Native team to build out our features and stress test the framework as they were gearing up for a public launch.</p>
        <div class="mockups_container">
          <img src={facebook_ama_home} class="mockup" alt="" />
          <img src={facebook_ama_campaign} class="mockup" alt="" />
        </div>
        <p class="subtitle">The homepage gives you a high-level overview of how your campaigns are performing, and allows you to dive into each one for more detailed performance data.</p>
        <div class="mockups_container">
          <img src={facebook_ama_create1} class="mockup" alt="" />
          <img src={facebook_ama_create2} class="mockup" alt="" />
        </div>
        <p class="subtitle">The ad creation flow: the most challenging piece to design. This flow captures all of the power and functionality of creating ads in the desktop Ads Manager, and breaks each step out into its own screen to not overwhelm advertisers, allow them to focus, and to save drafts along the way. </p>
  </Layout>
)

export default FacebookPage